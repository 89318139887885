import React from 'react'
import styled from 'styled-components'
import ContactForm from '@/components/ContactForm/ContactForm'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import ContentContainer from '@/components/UI/ContentContainer'

const Wrapper = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Title = styled.div`
  display: inline-block;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  margin-bottom: 60px;
`
interface GraphqlProps {
  backgroundImage: any
  allContentfulContactUsFormSection: {
    nodes: {
      title: string
    }[]
  }
}

const ContactFormSection: React.FC = () => {
  const {
    allContentfulContactUsFormSection: { nodes },
    backgroundImage,
  }: GraphqlProps = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "contactFormBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allContentfulContactUsFormSection {
        nodes {
          title
        }
      }
    }
  `)

  const { title } = nodes[0]

  return (
    <BackgroundImageWrapper image={backgroundImage.childImageSharp.fluid}>
      <Wrapper>
        <Title
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          {title}
        </Title>
        <ContactForm />
      </Wrapper>
    </BackgroundImageWrapper>
  )
}

export default ContactFormSection
