import * as React from 'react'
import Layout from '@/components/layout'
import ContactUsHeader from '@/components/ContactUsPage/ContactUsHeader'
import ContactFormSection from '@/components/ContactUsPage/ContactFormSection'
import { Footer } from '@/components/Footer'
import { graphql, useStaticQuery } from 'gatsby'

const Contactus: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          contactUsPage
        }
      }
    }
  `)

  const { contactUsPage } = nodes[0]

  return (
    <Layout title={contactUsPage}>
      <ContactUsHeader />
      <ContactFormSection />
      <Footer />
    </Layout>
  )
}
export default Contactus
