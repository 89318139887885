import React from 'react'
import styled from 'styled-components'
import { Button } from '@/components/StyledComponents/Button'
import { graphql, useStaticQuery } from 'gatsby'
import ContentContainer from '@/components/UI/ContentContainer'
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
import Title from '@/components/UI/Title'

const Wrapper = styled(ContentContainer)`
  background-color: ${({ theme }) => theme.colors.lightGray};
`

const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 20px;
`

const Cards = styled.div`
  justify-content: center;
  flex-flow: wrap;
  display: flex;
`

const Card = styled.div`
  img {
    max-width: 125px !important;

    @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
      max-width: 186px !important;
    }
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    height: 186px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 200px;
  padding-block: 10px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    width: 220px;
    height: 50px;
  }
`

const Section = styled.div`
  text-align: center;
  margin-inline: 30px;
  width: 250px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    margin-top: 60px;
  }
`
interface GraphqlProps {
  allContentfulContactUsSection: {
    nodes: {
      icons: { icon: IGatsbyImageData; buttonText: string }[]
      title: string
    }[]
  }
}

const ContactUsHeader: React.FC = () => {
  const {
    allContentfulContactUsSection: { nodes },
  }: GraphqlProps = useStaticQuery(graphql`
    {
      allContentfulContactUsSection {
        nodes {
          icons {
            icon {
              gatsbyImageData(quality: 100)
            }

            buttonText
          }
          title
        }
      }
    }
  `)

  const { icons, title } = nodes[0]

  const cardElements = icons.map((item, key) => {
    const mockedImage = getImage(item.icon)

    return (
      <Section
        data-sal="slide-down"
        data-sal-duration="1000"
        data-sal-delay={`${200 * key}`}
        data-sal-easing="easeOutQuad"
      >
        <Card>
          <GatsbyImage
            image={mockedImage as IGatsbyImageData}
            alt={'icon'}
          />
        </Card>
        <StyledButton>{item.buttonText}</StyledButton>
      </Section>
    )
  })

  return (
    <Wrapper>
      <StyledTitle
        data-sal="slide-down"
        data-sal-duration="1000"
        data-sal-easing="easeOutQuad"
      >
        {title}
      </StyledTitle>
      <Cards>{cardElements}</Cards>
    </Wrapper>
  )
}

export default ContactUsHeader
